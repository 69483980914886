@font-face {
  font-family: "montystay";
  font-display: swap;
  src: local("montystay"),
    url("https://abode-fonts.s3.ap-south-1.amazonaws.com/Montserrat-Regular.4fff79a0.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montystay";
  font-display: swap;
  src: local("montystay"),
    url("https://abode-fonts.s3.ap-south-1.amazonaws.com/Montserrat-Medium.bd2f827c.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}

body {
  height: 100%;
  width: 100%;
  border-top: 5px solid #bae7ff;
}

.app {
  width: 100%;
  max-width: 650px;
  padding: 1em 1em 10em 1em;
}
.ant-input:focus {
  box-shadow: none !important;
}
input {
  text-transform: capitalize;
}
.email {
  text-transform: none;
}
.steps-action {
  margin: 2em 0;
}

.ant-form {
  width: 100%;
}

.ant-form-explain {
  margin-top: 2px !important;
}
.ant-form-item-label {
  width: 100%;
  text-align: left !important;
}

.ant-row:after,
.ant-row:before {
  content: normal;
}
.header {
  position: relative;
  z-index: 3;
  font-family: "montystay", sans-serif;
  align-items: center;
  h4 {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 50px;
    position: absolute;
    top: 0;
  }
  .content {
    h2 {
      font-size: 50px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 10px;
      width: 100%;
    }
    p {
      width: 100%;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
}
.home-container,
.thanks-container {
  .top-banner {
    width: 100%;
    img {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      object-fit: cover;
      width: 100%;
    }
    #color-overlay {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
    }
  }
}
.home-container {
  .header {
    height: 256px;
  }
  .top-banner {
    img,
    #color-overlay {
      height: 256px;
    }
  }
}
.thanks-container {
  .header {
    height: 400px;
  }
  .top-banner {
    img,
    #color-overlay {
      height: 400px !important;
    }
  }
}

.logoImage {
  width: 26%;
  margin-bottom: 30px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media only screen and(max-width: 768px) {
  .header {
    position: absolute;
    .content {
      h4 {
        font-size: 24px;
      }
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .top-banner {
    height: 256px;
  }
}

@media only screen and(min-width: 700px) {
  body {
    display: flex;
    justify-content: center;
  }
}
